/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import {
  FilterAction,
  Wrap,
  WrapperLabel,
  SelectItem,
  ButtonSearch,
  SelectItemLarge,
  ItemInput,
  SelectItemSmall,
} from "./styled";
import { Form, Pagination, Select, Table } from "antd";
import { FormProvider, useForm } from "react-hook-form";
import { SearchOutlined } from "@ant-design/icons";
import { FormInput, Notification } from "Components";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { getInvalidCourseAdmin } from "Apis/admin";
import { useSelector ,useDispatch } from "react-redux";
import actions from "Store/Actions";
import { formatDate } from "Utils/convertValue";
import { ErrorField, ErrorMessage, TableContainer } from "Layouts/Admin/styled";
import ModalDetailAdmin from "../ModalDetail/ModalDetailAdmin";
import moment from "moment";
import { textNoData } from "Utils/data-default";
import { ERROR_API_MESSAGE } from "Constants";
const {
  getSeriesAdmin,
  getSubject,
  getClassification,
  getMethod,
  getContent,
  getEdited,
  getExam,
  getVersion,
} = actions;
const HomeAdmin = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [sizePage, setSizePage] = useState(10);
  const [total, setTotal] = useState(50);
  const [listCourse, setListCourse] = useState();
  const [loaddingPage, setLoaddingPage] = useState(true);
  const [dataSearch, setDataSearch] = useState({})
  const [resetPage, setResetPage] = useState(1)
  const [openDetailModal, setOpenDetaiModal] = useState(false);
  const [dataDetail, setDataDetail] = useState();
  const [isOpen, setIsOpen] = useState({
    series_code: false,
    subject_name:false,
    classification_code:false,
    method:false,
    content:false,
    course_name:false,
    edited:false,
    exam:false,
    version:false,
    from_level: false,
    to_level: false,
  });
  const {
    dataSeries,
    dataSubject,
    dataClassification,
    dataMethod,
    dataContent,
    dataEdited,
    dataExam,
    dataVersion,
  } = useSelector((state) => state.admin);
  const dispatch = useDispatch();
  useEffect(() => {
    !dataSeries.length && dispatch(getSeriesAdmin((action, res) => {}));
    !dataSubject.length && dispatch(getSubject((action, res) => {}));
    !dataClassification.length && dispatch(getClassification((action, res) => {}));
    !dataMethod.length && dispatch(getMethod((action, res) => {}));
    !dataContent.length && dispatch(getContent((action, res) => {}));
    !dataEdited.length && dispatch(getEdited((action, res) => {}));
    !dataExam.length &&  dispatch(getExam((action, res) => {}));
    !dataVersion.length && dispatch(getVersion((action, res) => {}));
  }, []);
  
  useEffect(() => {
    async function fetchData() {
      let dataFilter = {};
      if (dataSearch) {
        for (let key in dataSearch) {
          if (
            dataSearch.hasOwnProperty(key) &&
            dataSearch[key] !== "" &&
            dataSearch[key] !== undefined
          ) {
            dataFilter[key] = dataSearch[key];
          }
        }
      }
      try {
        const data = await getInvalidCourseAdmin({
          page: currentPage  - 1,
          size: sizePage,
          dataSearch: dataFilter,
        });
        const result = data.data;
        setTotal(result.total);
        setListCourse(result.items);
        setLoaddingPage(false);
        
      } catch (error) {
        setLoaddingPage(false);
        Notification.error(
          ERROR_API_MESSAGE[error.message_code] || ERROR_API_MESSAGE[error.code] ||
            error.message ||
            error.errors?.[0].msg
        )
      }
    }
    fetchData();
  }, [sizePage, currentPage, dataSearch, resetPage]);
  
  const columns = [
    {
      title: "連番",
      render: (text, record, index) =>
        <span className="id_record" onClick={() => handleShowDetail(record)}>
          {(currentPage - 1) * sizePage + index + 1}
        </span>,
      key: "stt",
    },
    {
      title: "講座ID",
      dataIndex: "course_id",
      key: "course_id",
    },
    {
      title: "シリーズ名",
      dataIndex: "series_name",
      key: "series_name",
      render: (course_name) => (
        <span className="course__name">{course_name}</span>
      )
    },
    {
      title: "分類名",
      dataIndex: "classification_name",
      key: "classification_name",
      className:'min-150'
    },
    {
      title: "科目",
      dataIndex: "subject_name",
      key: "subject_name",
    },
    {
      title: "映・添",
      dataIndex: "method",
      key: "method",
    },
    {
      title: "内容",
      dataIndex: "content",
      key: "content",
    },
    {
      title: "講座名",
      dataIndex: "course_name",
      key: "course_name",
      render: (course_name) => (
        <span className="course__name">{course_name}</span>
      ),
    },
    {
      title: "答案添削",
      dataIndex: "edited",
      key: "edited",
      className: "data__edited",
    },
    {
      title: "総講数",
      dataIndex: "number_of_periods",
      key: "number_of_periods",
    },
    {
      title: "スタートレベル",
      dataIndex: "from_level",
      key: "from_level",
    },
    {
      title: "エンドレベル",
      dataIndex: "to_level",
      key: "to_level",
    },
    {
      title: "受験分類",
      dataIndex: "exam",
      key: "exam",
    },
    {
      title: "エラー説明",
      dataIndex: "reason",
      key: "reason",
      className:'min-150'
    },
    {
      title: "バージョン",
      dataIndex: "version",
      key: "version",
    },
    {
      title: "インポート日付",
      dataIndex: "create_at",
      key: "create_at",
      className:'min-150',
      render: (create_at) => (
        <span >{formatDate(create_at)}</span>
      ),
    },
  ];

  const validationSchema = yup
    .object({
      series_code: yup.string(),
      subject_name:yup.string(),
      classification_code:yup.string(),
      method:yup.string(),
      content:yup.string(),
      course_name:yup.string(),
      edited:yup.string(),
      exam:yup.number(),
      version:yup.number(),
      from_level: yup
        .number()
        .test("from-level-less-than-to-level", "スタートレベルをエンドレベルより小さい数字で選択してください。", function (value) {
        const toLevel = this.resolve(yup.ref("to_level"));
        return value === undefined || toLevel === undefined || value < toLevel;
      }),
      to_level: yup.number(),
      import_from: yup.string().test('check-import-to', '開始日は終了日よりの前に日付を選択してください', function (value) {
        const fromDate = moment(value, "YYYY-MM-DD");
        const toDate = moment(this.parent.import_to, "YYYY-MM-DD");
        return  fromDate < toDate || !this.parent.import_to || !value;
      }) ,
      import_to: yup.string(),           
    })
  const form = useForm({
    resolver: yupResolver(validationSchema),
  });

  const {
    handleSubmit,
    setValue,
    trigger,
    formState: { errors }
  } = form;
  const onSubmit = async (dataFilter) => {
    setDataSearch(dataFilter)
    setCurrentPage(1)
    setResetPage((pre)=>pre+1)
  }
  const handlePageChange = (page, pageSize) => {
    setCurrentPage(page);
    setSizePage(pageSize);
  };
  const dataSource = listCourse ? listCourse?.map((item, index) => ({
    ...item,
    key: index,
  })): [];
  const optionsLevel = [
    { label: "1", value: 1 },
    { label: "2", value: 2 },
    { label: "3", value: 3 },
    { label: "4", value: 4 },
    { label: "5", value: 5 },
    { label: "6", value: 6 },
    { label: "7", value: 7 },
    { label: "8", value: 8 },
  ];
  const handleShowDetail = async (data) => {
    setOpenDetaiModal(true)
    const detail = {
      "シリーズ名": data?.series_name,
      "分類名" : data?.classification_name,
      "科目" : data?.subject_name,
      "映・添" : data?.method,
      "内容" : data?.content,
      "講座名" : data?.course_name,
      "答案添削" : data?.edited,
      "総講数" : data?.number_of_periods,
      "スタートレベル" : data?.from_level,
      "エンドレベル" : data?.to_level,
      "受験分類": data?.exam,
      "エラー説明": data?.reason,
      "バージョン" : data?.version,
      "インポート日付" : formatDate(data?.create_at),
    }
    setDataDetail(detail)
  };
  const handleSelectKeyDown = (e) => {
    if (e.key === 'Enter') {
      handleSubmit(onSubmit)();
    }
  };
  return (
    <Wrap>
      <FormProvider {...form}>
        <form id="information-form" autoComplete="off"
          // onSubmit={handleSubmit(onSubmit)}
        >
          <FilterAction>
            <SelectItemLarge>
              <Form.Item label={<WrapperLabel>シリーズ</WrapperLabel>}>
              <Select
                className="select_type"
                name="series_code"
                showSearch
                options={dataSeries}
                allowClear
                optionFilterProp="children"
                filterOption={(input, option) =>
                  (option?.label ?? "").includes(input)
                }
                onChange={e => setValue("series_code", e)}
                notFoundContent={textNoData}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    setIsOpen((prevState) => ({
                      ...prevState,
                      series_code: false,
                    }));
                  }
                  handleSelectKeyDown(e)
                }}
                open={isOpen.series_code}
                onDropdownVisibleChange={(open) => {
                  setIsOpen((prevState) => ({
                    ...prevState,
                    series_code: open,
                  }));
                }}
                />
              </Form.Item>
            </SelectItemLarge>
            <SelectItemLarge>
              <Form.Item label={<WrapperLabel>分類</WrapperLabel>}>
                <Select
                  className="select_type"
                  name="classification_code"
                  showSearch
                  options={dataClassification}
                  allowClear
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    (option?.label ?? "").includes(input)
                  }
                  onChange={e => setValue("classification_code", e)}
                  notFoundContent={textNoData}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      setIsOpen((prevState) => ({
                        ...prevState,
                        classification_code: false,
                      }));
                    }
                    handleSelectKeyDown(e)
                  }}
                  open={isOpen.classification_code}
                  onDropdownVisibleChange={(open) => {
                    setIsOpen((prevState) => ({
                      ...prevState,
                      classification_code: open,
                    }));
                  }}
                />
              </Form.Item>
            </SelectItemLarge>
            <SelectItemSmall>
              <Form.Item label={<WrapperLabel>科目</WrapperLabel>}>
                <Select
                  className="select_type"
                  name="subject_name"
                  showSearch
                  options={dataSubject}
                  allowClear
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    (option?.label ?? "").includes(input)
                  }
                  onChange={e => setValue("subject_name", e)}
                  notFoundContent={textNoData}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      setIsOpen((prevState) => ({
                        ...prevState,
                        subject_name: false,
                      }));
                    }
                    handleSelectKeyDown(e)
                  }}
                  open={isOpen.subject_name}
                  onDropdownVisibleChange={(open) => {
                    setIsOpen((prevState) => ({
                      ...prevState,
                      subject_name: open,
                    }));
                  }}
                />
              </Form.Item>
            </SelectItemSmall>
            <SelectItemSmall>
              <Form.Item label={<WrapperLabel>映・添</WrapperLabel>}>
                <Select
                  className="select_type"
                  name="method"
                  showSearch
                  options={dataMethod}
                  allowClear
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    (option?.label ?? "").includes(input)
                  }
                  onChange={e => setValue("method", e)}
                  notFoundContent={textNoData}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      setIsOpen((prevState) => ({
                        ...prevState,
                        method: false,
                      }));
                    }
                    handleSelectKeyDown(e)
                  }}
                  open={isOpen.method}
                  onDropdownVisibleChange={(open) => {
                    setIsOpen((prevState) => ({
                      ...prevState,
                      method: open,
                    }));
                  }}
                />
              </Form.Item>
            </SelectItemSmall>
            <SelectItemSmall>
              <Form.Item label={<WrapperLabel>内容</WrapperLabel>}>
                <Select
                  className="select_type"
                  name="content"
                  showSearch
                  options={dataContent}
                  allowClear
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    (option?.label ?? "").includes(input)
                  }
                  onChange={e => setValue("content", e)}
                  notFoundContent={textNoData}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      setIsOpen((prevState) => ({
                        ...prevState,
                        content: false,
                      }));
                    }
                    handleSelectKeyDown(e)
                  }}
                  open={isOpen.content}
                  onDropdownVisibleChange={(open) => {
                    setIsOpen((prevState) => ({
                      ...prevState,
                      content: open,
                    }));
                  }}
                />
              </Form.Item>
            </SelectItemSmall>
            <SelectItemSmall>
              <Form.Item label={<WrapperLabel>答案添削</WrapperLabel>}>
                <Select
                  className="select_type"
                  name="edited"
                  showSearch
                  options={dataEdited}
                  allowClear
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    (option?.label ?? "").includes(input)
                  }
                  onChange={e => setValue("edited", e)}
                  notFoundContent={textNoData}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      setIsOpen((prevState) => ({
                        ...prevState,
                        edited: false,
                      }));
                    }
                    handleSelectKeyDown(e)
                  }}
                  open={isOpen.edited}
                  onDropdownVisibleChange={(open) => {
                    setIsOpen((prevState) => ({
                      ...prevState,
                      edited: open,
                    }));
                  }}
                />
              </Form.Item>
            </SelectItemSmall>
            <SelectItemSmall>
              <Form.Item label={<WrapperLabel>スタートレベル</WrapperLabel>} validateStatus={errors.from_level ? "error" : ""}>
                <Select className="select_type" name="from_level" showSearch
                options={optionsLevel}
                allowClear
                optionFilterProp="children"
                filterOption={(input, option) =>
                  (option?.label ?? "").includes(input)
                }
                  onChange={e => {
                    trigger('to_level')
                    trigger('from_level')
                    setValue("from_level", e)
                  }}
                  notFoundContent={textNoData}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      setIsOpen((prevState) => ({
                        ...prevState,
                        from_level: false,
                      }));
                    }
                    handleSelectKeyDown(e)
                  }}
                  open={isOpen.from_level}
                  onDropdownVisibleChange={(open) => {
                    setIsOpen((prevState) => ({
                      ...prevState,
                      from_level: open,
                    }));
                  }}
                />
              </Form.Item>
              <ErrorField>
                <ErrorMessage>{errors?.from_level?.message}</ErrorMessage>
              </ErrorField>
            </SelectItemSmall>
            <SelectItemSmall>
              <Form.Item label={<WrapperLabel>エンドレベル</WrapperLabel>} validateStatus={errors.from_level ? "error" : ""}>
                <Select className="select_type" name="to_level" showSearch
                options={optionsLevel}
                allowClear
                optionFilterProp="children"
                filterOption={(input, option) =>
                  (option?.label ?? "").includes(input)
                }
                  onChange={e => {
                    trigger('from_level')
                    setValue("to_level", e)
                  }}
                  notFoundContent={textNoData}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      setIsOpen((prevState) => ({
                        ...prevState,
                        to_level: false,
                      }));
                    }
                    handleSelectKeyDown(e)
                  }}
                  open={isOpen.to_level}
                  onDropdownVisibleChange={(open) => {
                    setIsOpen((prevState) => ({
                      ...prevState,
                      to_level: open,
                    }));
                  }}
                />
              </Form.Item>
            </SelectItemSmall>
          </FilterAction>
          <FilterAction>
            <ItemInput>
              <FormInput label="講座名" placeholder='例）マジカル英文法／稲毛重典先生' name="course_name" type="text" allowClear />
            </ItemInput>
            <SelectItem>
              <Form.Item label={<WrapperLabel>受験分類</WrapperLabel>}>
                <Select
                  className="select_type"
                  name="exam"
                  showSearch
                  options={dataExam}
                  allowClear
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    (option?.label ?? "").includes(input)
                  }
                  onChange={e => setValue("exam", e)}
                  notFoundContent={textNoData}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      setIsOpen((prevState) => ({
                        ...prevState,
                        exam: false,
                      }));
                    }
                    handleSelectKeyDown(e)
                  }}
                  open={isOpen.exam}
                  onDropdownVisibleChange={(open) => {
                    setIsOpen((prevState) => ({
                      ...prevState,
                      exam: open,
                    }));
                  }}
                />
              </Form.Item>
            </SelectItem>
            <SelectItemLarge>
              <Form.Item label={<WrapperLabel>バージョン</WrapperLabel>}>
                <Select
                  className="select_type"
                  name="version"
                  showSearch
                  options={dataVersion}
                  allowClear
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    (String(option?.label ?? "") ).includes(input)
                  }
                  onChange={e => setValue("version", e)}
                  notFoundContent={textNoData}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      setIsOpen((prevState) => ({
                        ...prevState,
                        version: false,
                      }));
                    }
                    handleSelectKeyDown(e)
                  }}
                  open={isOpen.version}
                  onDropdownVisibleChange={(open) => {
                    setIsOpen((prevState) => ({
                      ...prevState,
                      version: open,
                    }));
                  }}
                />
              </Form.Item>
            </SelectItemLarge>
            <ButtonSearch htmlType="submit" onClick={handleSubmit(onSubmit)}>
              <SearchOutlined />
              検索
            </ButtonSearch>
          </FilterAction>
        </form>
      </FormProvider>
      <TableContainer>
        <Table
          columns={columns}
          loading={loaddingPage}
          dataSource={dataSource}
          pagination={false}
          scroll={{ y: 440, x: true }}
          locale={{
            emptyText: textNoData,
          }}
        />
        {dataSource && dataSource.length >0  && (
          <Pagination
            style={{ marginTop: "16px", textAlign: "right" }}
            total={total}
            current={currentPage}
            onChange={handlePageChange}
            showSizeChanger={true}
            pageSize={sizePage}
            locale={{
              items_per_page: "/ ページ",
            }}
          />
        )}
      </TableContainer>
      <ModalDetailAdmin
      visibleModal={openDetailModal}
      onCancel={() => setOpenDetaiModal(false)}
      onOk={() => setOpenDetaiModal(false)}
      dataDetail={dataDetail}
      />
    </Wrap>
  );
};

export default HomeAdmin;
