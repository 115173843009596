import { Button } from "antd";
import styled from "styled-components";

export const Wrap = styled.div`
  .seihoku__import {
    display: flex;
    justify-content: space-between;
    h1{
      margin-right: 15px;
    }
  }
  .ant-form-item {
    height: max-content;
    width: 100%;
    margin-bottom: 0px;

    .ant-select {
      min-height: 40px !important;
      border-radius: 5px;
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;
      .ant-select-selector {
        min-height: 40px;
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        color: #000000 !important;

        .ant-select-selection-search-input {
          height: 100%;
        }

        .ant-select-selection-item {
          display: flex;
          align-items: center;
        }
        
      }
    }
    .ant-select-clear{
      display: flex !important;
    }

    .ant-form-item-row {
      display: flex;
      flex-direction: column;
    }

    .ant-form-item-label {
      text-align: start;
    }

    .ant-select-disabled {
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;
      color: #000000 !important;
    }

    .select__menu {
      z-index: 10;
    }

    .select__control {
      border: ${({ validateStatus, theme }) =>
        validateStatus === "error"
          ? `1px solid ${theme.error_ant} !important`
          : ""};
      box-shadow: ${({ validateStatus }) =>
        validateStatus === "error" ? "none" : ""};
    }

    .ant-form-item-children-icon {
      display: none;
    }

    .select__dropdown-indicator {
      color: hsl(0, 0%, 60%);
    }

    label::after {
      content: "" !important;
    }

  }
`;
export const ActionUpload = styled.label`
  height: 40px;
  letter-spacing: 1px;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 2rem;
  color: #ffffff;
  font-family: "Public Sans";
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  background-color: rgb(22, 119, 255);
  border: none;
  padding: 0 10px;
  cursor: pointer;
  width: 150px;
  @media only screen and (max-width: 768px) {
    font-size: 10px;
    height: 30px;
    line-height: 10px;
  }
  input[type="file"] {
    display: none;
  }

  .label-wrapper:hover {
    background-color: #1976d2;
  }

  .label-wrapper input[type="file"] {
    display: none;
  }

  .label-wrapper span {
    margin-left: 10px;
  }
`;


export const FilterAction = styled.div`
  display: flex;
  gap: 0 15px;
  align-items: end;
  max-width: 1300px;
  @media only screen and (max-width: 1400px) {
    flex-wrap: wrap;
  }
`;
export const SelectItem = styled.div`
  max-width: 200px;
  width: 100%;
`;
export const ItemInput = styled.div`
  width: 400px;
  min-width: 400px;
  @media only screen and (max-width: 1200px) {
    min-width: unset;
    max-width: 400px;
    width: 100%;
  }
`;
export const SelectItemLarge = styled.div`
  max-width: 240px;
  width: 100%;
`;
export const SelectDatePicker = styled.div`
  max-width: 260px;
  width: 100%;
  .ant-picker {
    min-height: 40px !important;
    border-radius: 5px;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    width: 100%;
  }
`;
export const WrapperLabel = styled.div`
  width: 100%;
  font-weight: 500;
  font-size: 1.3rem;
  line-height: 30px;
  color: #6d737a;
`;
export const ButtonSearch = styled(Button)`
  height: 40px;
  letter-spacing: 1px;
  font-style: normal;
  font-weight: 700;
  font-size: 1.3rem;
  line-height: 2rem;
  color: #ffffff;
  font-family: "Public Sans";
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  background-color: rgb(22, 119, 255);
  margin-top: 15px;
`;

